import getEditableDateDef from '../../../utils/Dispatch/Common/Generator/getEditableDateDef';
import getEditableSelectDef from '../../../utils/Dispatch/Common/Generator/getEditableSelectDef';
import EDITABLE_CHASSIS_DEF from './editableChassisDef';
import EDITABLE_CHECKBOX_DEF from './editableCheckBoxDef';
import EDITABLE_TEXT_DEF from './editableTextDef';
import getEditableBookingDef from '../../../utils/Dispatch/Common/Generator/getEditableBookingDef';
import getEditableDatetimeDef from '../../../utils/Dispatch/Common/Generator/getEditableDatetimeDef';
import cellEditorGenerator from '../../../utils/Common/Datagrid/Generator/cellEditorGenerator';
import { CELL_TYPE_TEXT } from '../../Common/DataGrid/cellType';

const EDITABLE_COL_DEF = {
  ch_no: EDITABLE_CHASSIS_DEF,
  pool: selectOption => getEditableSelectDef(selectOption),

  lfd: getEditableDateDef({ field: 'lfd' }),
  lfd_date: getEditableDateDef({ field: 'lfd' }),

  bk_no: getEditableBookingDef(),
  st: getEditableDateDef({ field: 'st' }),

  po_schedule: getEditableDatetimeDef({ field: 'po_schedule' }),
  dilv_schedule: getEditableDatetimeDef({ field: 'dilv_schedule' }),
  pu_schedule: getEditableDatetimeDef({ field: 'pu_schedule' }),
  rtn_schedule: getEditableDatetimeDef({ field: 'rtn_schedule' }),

  dilv_load_cfm: getEditableDateDef({ field: 'empty_load_cfm' }),
  dilv_empty_cfm: getEditableDateDef({ field: 'empty_load_cfm' }),
  dilv_empty_load_cfm: getEditableDateDef({ field: 'empty_load_cfm' }),

  ava_dsc: EDITABLE_CHECKBOX_DEF,
  ava_obl: EDITABLE_CHECKBOX_DEF,
  ava_cus: EDITABLE_CHECKBOX_DEF,
  over_height: EDITABLE_CHECKBOX_DEF,
  over_weight: EDITABLE_CHECKBOX_DEF,
  hazmat: EDITABLE_CHECKBOX_DEF,
  hot: EDITABLE_CHECKBOX_DEF,

  /** It declared separately because of max-length property */
  remarks: {
    cellEditorSelector: gridParam =>
      cellEditorGenerator({
        gridParam,
        cellType: CELL_TYPE_TEXT,
        maxLength: 512,
      }),
  },
};

export default EDITABLE_COL_DEF;
