import React from 'react';
import { useSelector } from 'react-redux';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import HeaderButtons from '../../../components/TmsReport/Schedules/HeaderButtons';
import WeeklyTable from '../../../components/TmsReport/Schedules/WeeklyTable';
import PO_NAME_SET from '../../../constants/TmsReport/PullOut/pullOutNameSet';
import useGetSchedule from '../../../hooks/TmsReport/Schedules/useGetSchedule';
import useControlQueries from '../../../hooks/TmsReport/Schedules/useControlQueries';
import useFilterStorage from '../../../hooks/Common/Filter/useFilterStorage';

const PullOut = () => {
  const divList = useSelector(state => state?.common?.allDiv);

  const filterStorage = useFilterStorage({
    storageName: 'pullOutFilter',
  });

  const {
    setScheduleTypeQuery,
    setDivisionQuery,
    setCategoryQuery,
    setDateQuery,
    allQueries,
  } = useControlQueries({ divList, filterStorage });

  const querySetters = {
    setScheduleTypeQuery,
    setDivisionQuery,
    setCategoryQuery,
    setDateQuery,
  };

  const {
    isLoading: isWeeklyLoading,
    isFetching: isWeeklyFetching,
    data: weeklyData,
  } = useGetSchedule({
    type: 'po',
    ...allQueries,
  });

  return (
    <>
      <div className="flex flex-col pr-[40px] pb-[20px] h-full w-full">
        <HeaderButtons
          allQueries={allQueries}
          divList={divList}
          filterStorage={filterStorage}
          {...querySetters}
        />
        <WeeklyTable
          weeklyData={weeklyData}
          nameSet={PO_NAME_SET}
          allQueries={allQueries}
        />
      </div>
      <CommonLoading open={isWeeklyLoading || isWeeklyFetching} />
    </>
  );
};

export default PullOut;
