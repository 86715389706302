import React from 'react';
import WEEK_DAY_LIST from '../../../../constants/TmsReport/Schedules/weeklyDayList';
import WeeklyTableItem from '../WeeklyTableItem';
import WeeklyTableHeader from '../WeeklyTableHeader';
import WeeklyTableContent from '../WeeklyTableContent';
import StyledWeeklyTableWrapper from '../../../../styles/TmsReport/Schedules/StyledWeeklyTableWrapper';
import StyledWeeklyTableColumnWrapper from '../../../../styles/TmsReport/Schedules/StyledWeeklyTableColumnWrapper';

const WeeklyTable = ({ weeklyData, nameSet, allQueries }) => {
  return (
    <StyledWeeklyTableWrapper>
      {WEEK_DAY_LIST?.map(day => (
        <StyledWeeklyTableColumnWrapper key={day}>
          <WeeklyTableHeader
            page={nameSet?.title?.replace(' ', '-')?.toLowerCase()}
            weeklyData={weeklyData}
            day={day}
            allQueries={allQueries}
          />
          <WeeklyTableContent
            key={day}
            day={day}
            weeklyData={weeklyData}
            nameSet={nameSet}
          />
        </StyledWeeklyTableColumnWrapper>
      ))}
    </StyledWeeklyTableWrapper>
  );
};

export default WeeklyTable;
