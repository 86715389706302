const MAIN_FIELD_WIDTH = {
  category: 42,
  created_date: 62,
  wo_no: 100,
  billto: 76,
  cont_no: 88,
  trailer_no: 80,
  bk_no: 105,
  ssl: 50,
  vessel: 60,
  size: 42,
  type: 57,
  ch_no: 86,
  ch_type: 77,
  pool: 52,
  bond_entry: 62,
  ref_no: 85,
  purchase_no: 62,
  mbl_no: 118,
  wgt: 54,
  qty: 46,
  empty_load_cfm: 78,
  empty_load_status: 47,
  profit: 60,
  inv: 91,
  wms: 46,

  over_height: 40,
  over_weight: 40,
  hazmat: 40,
  hot: 40,

  st: 62,
  erd: 62,
  cutoff: 95,
  rfd: 62,
  lfd: 62,
  eta: 62,
  ava_date: 76,

  po_location: 76,
  po_sche_date: 98,
  po_actual_in_date: 98,
  po_actual_out_date: 98,
  po_driver: 72,

  dilv_location: 76,
  dilv_sche_date: 98,
  dilv_actual_in_date: 98,
  dilv_actual_out_date: 98,
  dilv_driver: 72,

  pu_location: 76,
  pu_sche_date: 98,
  pu_actual_in_date: 98,
  pu_actual_out_date: 98,
  pu_driver: 72,

  rtn_location: 76,
  rtn_sche_date: 98,
  rtn_actual_in_date: 98,
  rtn_actual_out_date: 98,
  rtn_driver: 72,

  ar: 52,
  trucking: 52,
  ar_fsc: 52,
  ar_ch: 52,
  ar_dr: 52,
  ar_pe: 52,
  ar_de: 52,

  ap: 52,
  ap_drv: 54,
  ap_ch: 52,
  ap_pe: 52,
  ap_de: 52,
  ap_dr: 52,

  doc_pod: 42,
  doc_bl: 42,
  doc_do: 42,
  doc_it: 42,

  remarks: 150,
};

const getWidth = item => {
  return MAIN_FIELD_WIDTH?.[item] ? { width: MAIN_FIELD_WIDTH?.[item] } : {};
};

export default getWidth;
