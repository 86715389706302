import handleCheckboxChange from '../../../TmsReport/Common/handleCheckboxChange';

const getCheckboxOptions = ({
  setScheduleTypeQuery,
  setDivisionQuery,
  setCategoryQuery,
  setYardQuery,
  setStatusQuery,
  allQueries,
  yardList,
  divList,
  filterStorage,
}) => {
  const defaultDiv = localStorage.getItem('defaultDiv');
  const scheduleTypeOptionList = [
    {
      label: 'LIVE',
      value: 'LIVE',
      defaultValue: filterStorage?.filter?.sche?.LIVE,
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setScheduleTypeQuery,
          item: 'sche',
          filterStorage,
        }),
    },
    {
      label: 'DROP',
      value: 'DROP',
      defaultValue: filterStorage?.filter?.sche?.DROP,
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setScheduleTypeQuery,
          item: 'sche',
          filterStorage,
        }),
    },
  ];

  const divisionOptionList = [
    ...divList?.map(div => {
      return {
        label: div,
        value: div,
        defaultValue:
          typeof filterStorage?.filter?.div?.[div] === 'boolean'
            ? filterStorage?.filter?.div?.[div]
            : div === defaultDiv,
        onChange: ({ ...props }) =>
          handleCheckboxChange({
            ...props,
            setQuery: setDivisionQuery,
            item: 'div',
            filterStorage,
          }),
      };
    }),
  ];

  const categoryOptionList = [
    {
      label: 'IMPORT',
      value: 'M',
      defaultValue: filterStorage?.filter?.cat?.M,
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setCategoryQuery,
          item: 'cat',
          filterStorage,
        }),
    },
    {
      label: 'EXPORT',
      value: 'X',
      defaultValue: filterStorage?.filter?.cat?.X,
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setCategoryQuery,
          item: 'cat',
          filterStorage,
        }),
    },
  ];

  const yardOptionList = [
    ...yardList?.map(yard => ({
      label: yard,
      value: yard,
      defaultValue:
        typeof filterStorage?.filter?.['y-com']?.[yard] === 'boolean'
          ? filterStorage?.filter?.['y-com']?.[yard]
          : false,
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setYardQuery,
          item: 'y-com',
          filterStorage,
        }),
    })),
  ];

  const statusOptionList = [
    {
      label: 'IN YARD',
      value: 'W',
      defaultValue: filterStorage?.filter?.color?.W,
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setStatusQuery,
          item: 'color',
          filterStorage,
        }),
    },
    {
      label: 'TO YARD',
      value: 'Y',
      defaultValue: filterStorage?.filter?.color?.Y,
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setStatusQuery,
          item: 'color',
          filterStorage,
        }),
    },
    {
      label: 'IN RTN',
      value: 'B',
      defaultValue: filterStorage?.filter?.color?.B,
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setStatusQuery,
          item: 'color',
          filterStorage,
        }),
    },
    {
      label: 'TO RTN',
      value: 'G',
      defaultValue: filterStorage?.filter?.color?.G,
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery: setStatusQuery,
          item: 'color',
          filterStorage,
        }),
    },
  ];

  return {
    scheduleTypeOptionList,
    divisionOptionList,
    categoryOptionList,
    yardOptionList,
    statusOptionList,
  };
};

export default getCheckboxOptions;
