const handleWOClick = item => {
  let category;
  if (item.category === 'IMP') {
    category = 'import';
  } else if (item.category === 'EXP') {
    category = 'export';
  } else if (item.category === 'VAN') {
    category = 'van';
  } else if (item.category === 'BROKERAGE') {
    category = 'brokerage';
  } else if (item.category === 'BARE CHASSIS') {
    category = 'bare-chassis';
  }
  const bcDetailTab = window.open(
    `/bc-detail/detail-info/${category}/${item.wo_no}`,
    'bc-detail-tab',
  );
};

export default handleWOClick;
