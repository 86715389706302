import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CommonButton from '../../CommonButton/CommonButton';
import CommonCheckboxList from '../../Inputs/CommonCheckboxList';
import getCheckboxOptions from '../../../utils/DailyReport/DailyReport/Generator/getCheckboxOption';
import exportToExcel from '../../../utils/Common/Datagrid/Handler/exportToExcel';
import handleAllCheckboxChange from '../../../utils/TmsReport/Common/handleAllCheckboxChange';
import { STATUS_CHECKBOX_LIST_STYLE } from '../../../constants/DailyReport/ReurnYard/CheckboxListStyle';

const ReturnYardHeader = ({
  gridRef,
  yardList,
  divList,
  setScheduleTypeQuery,
  setDivisionQuery,
  setCategoryQuery,
  setYardQuery,
  setStatusQuery,
  allQueries,
  filterStorage,
}) => {
  const [isYardOpen, setIsYardOpen] = useState(false);
  const [isScheduleTypeOpen, setIsScheduleTypeOpen] = useState(false);
  const [isDivisionOpen, setIsDivisionOpen] = useState(false);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [isStatusOpen, setIsStatusOpen] = useState(false);

  const controlOpen = ({ target, setTarget }) => {
    if (target) {
      setTarget(false);
    } else {
      setIsYardOpen(false);
      setIsScheduleTypeOpen(false);
      setIsDivisionOpen(false);
      setIsCategoryOpen(false);
      setIsStatusOpen(false);
      setTarget(true);
    }
  };

  const scheduleTypeController = useForm({});
  const divisionController = useForm({});
  const categoryController = useForm({});
  const yardController = useForm({});
  const statusController = useForm({});

  const {
    scheduleTypeOptionList,
    divisionOptionList,
    categoryOptionList,
    yardOptionList,
    statusOptionList,
  } = getCheckboxOptions({
    setScheduleTypeQuery,
    setDivisionQuery,
    setCategoryQuery,
    setYardQuery,
    setStatusQuery,
    allQueries,
    yardList,
    divList,
    filterStorage,
  });

  const headerName = 'RETURN YARD';

  return (
    <div className="flex flex-row justify-between pr-[40px]">
      <div className="whitespace-nowrap font-[800] text-[25px] pl-[5px] py-[24px]">
        {headerName}
      </div>
      <div className="flex flex-row place-items-end pb-[24px]">
        <FormProvider {...yardController}>
          <CommonCheckboxList
            isOpen={isYardOpen}
            options={yardOptionList}
            onChangeAll={({ ...props }) => {
              handleAllCheckboxChange({
                ...props,
                setQuery: setYardQuery,
                item: 'y-com',
                filterStorage,
              });
            }}
            suppressIndeterminate
            button={
              <CommonButton
                category="yard"
                onClick={() => {
                  controlOpen({
                    target: isYardOpen,
                    setTarget: setIsYardOpen,
                  });
                }}
                border="0"
                backgroundColor="transparent"
                color="#666666"
              >
                Yard
              </CommonButton>
            }
          />
        </FormProvider>
        <div className="flex h-[14px] border-l-[0.4px] border-[#00000099] self-end mb-[4px]" />
        <FormProvider {...scheduleTypeController}>
          <CommonCheckboxList
            isOpen={isScheduleTypeOpen}
            options={scheduleTypeOptionList}
            onChangeAll={({ ...props }) => {
              handleAllCheckboxChange({
                ...props,
                setQuery: setScheduleTypeQuery,
                item: 'sche',
                filterStorage,
              });
            }}
            suppressIndeterminate
            button={
              <CommonButton
                category="scheduleType"
                onClick={() => {
                  controlOpen({
                    target: isScheduleTypeOpen,
                    setTarget: setIsScheduleTypeOpen,
                  });
                }}
                border="0"
                backgroundColor="transparent"
                color="#666666"
              >
                Schedule Type
              </CommonButton>
            }
          />
        </FormProvider>
        <div className="flex h-[14px] border-l-[0.4px] border-[#00000099] self-end mb-[4px]" />
        <FormProvider {...divisionController}>
          <CommonCheckboxList
            isOpen={isDivisionOpen}
            options={divisionOptionList}
            onChangeAll={({ ...props }) => {
              handleAllCheckboxChange({
                ...props,
                setQuery: setDivisionQuery,
                item: 'div',
                filterStorage,
              });
            }}
            suppressIndeterminate
            button={
              <CommonButton
                category="customizeColumn"
                onClick={() => {
                  controlOpen({
                    target: isDivisionOpen,
                    setTarget: setIsDivisionOpen,
                  });
                }}
                border="0"
                backgroundColor="transparent"
                color="#666666"
              >
                Division
              </CommonButton>
            }
          />
        </FormProvider>
        <div className="flex h-[14px] border-l-[0.4px] border-[#00000099] self-end mb-[4px]" />
        <FormProvider {...categoryController}>
          <CommonCheckboxList
            isOpen={isCategoryOpen}
            options={categoryOptionList}
            onChangeAll={({ ...props }) => {
              handleAllCheckboxChange({
                ...props,
                setQuery: setCategoryQuery,
                item: 'cat',
                filterStorage,
              });
            }}
            suppressIndeterminate
            button={
              <CommonButton
                category="category"
                onClick={() => {
                  controlOpen({
                    target: isCategoryOpen,
                    setTarget: setIsCategoryOpen,
                  });
                }}
                border="0"
                backgroundColor="transparent"
                color="#666666"
              >
                Category
              </CommonButton>
            }
          />
        </FormProvider>
        <div className="flex h-[14px] border-l-[0.4px] border-[#00000099] self-end mb-[4px]" />
        <FormProvider {...statusController}>
          <CommonCheckboxList
            isOpen={isStatusOpen}
            options={statusOptionList}
            onChangeAll={({ ...props }) => {
              handleAllCheckboxChange({
                ...props,
                setQuery: setStatusQuery,
                item: 'color',
                filterStorage,
              });
            }}
            suppressIndeterminate
            style={STATUS_CHECKBOX_LIST_STYLE}
            button={
              <CommonButton
                category="status"
                onClick={() => {
                  controlOpen({
                    target: isStatusOpen,
                    setTarget: setIsStatusOpen,
                  });
                }}
                border="0"
                backgroundColor="transparent"
                color="#666666"
              >
                Status
              </CommonButton>
            }
          />
        </FormProvider>
        <CommonButton
          category="excel"
          onClick={() => {
            exportToExcel({ gridRef, fileName: headerName });
          }}
        >
          EXCEL
        </CommonButton>
      </div>
    </div>
  );
};

export default ReturnYardHeader;
