import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

const useControlQueries = ({ divList, filterStorage }) => {
  const defaultDiv = localStorage.getItem('defaultDiv');

  const { filter } = filterStorage;

  const [dateQuery, setDateQuery] = useState(
    filter?.date && dayjs?.(filter?.date)?.isValid()
      ? dayjs?.(filter?.date)?.format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD'),
  );

  const [scheduleTypeQuery, setScheduleTypeQuery] = useState({
    DROP: typeof filter?.sche?.DROP === 'boolean' ? filter?.sche?.DROP : true,
    LIVE: typeof filter?.sche?.LIVE === 'boolean' ? filter?.sche?.LIVE : true,
    LVAN: typeof filter?.sche?.LVAN === 'boolean' ? filter?.sche?.LVAN : true,
  });

  const [divisionQuery, setDivisionQuery] = useState({
    ...Object.fromEntries(
      divList?.map(div => [
        div,
        typeof filter?.div?.[div] === 'boolean'
          ? filter?.div?.[div]
          : div === defaultDiv,
      ]),
    ),
  });

  const [categoryQuery, setCategoryQuery] = useState({
    M: typeof filter?.cat?.M === 'boolean' ? filter?.cat?.M : true,
    X: typeof filter?.cat?.X === 'boolean' ? filter?.cat?.X : true,
    V: typeof filter?.cat?.V === 'boolean' ? filter?.cat?.V : true,
    B: typeof filter?.cat?.B === 'boolean' ? filter?.cat?.B : true,
    C: typeof filter?.cat?.C === 'boolean' ? filter?.cat?.C : true,
  });

  const [allQueries, setAllQueries] = useState({
    sche: scheduleTypeQuery,
    div: divisionQuery,
    cat: categoryQuery,
    date: dateQuery,
  });

  const [isStorageFilterExist, setIsStorageFilterExist] = useState(
    !!filterStorage?.filter,
  );

  useEffect(() => {
    setAllQueries(prev => ({ ...prev, date: dateQuery }));
  }, [dateQuery]);

  useEffect(() => {
    setAllQueries(prev => ({
      ...prev,
      sche: { ...scheduleTypeQuery },
      div: { ...divisionQuery },
    }));
  }, [scheduleTypeQuery, divisionQuery]);

  useEffect(() => {
    setAllQueries(prev => ({
      ...prev,
      sche: {
        ...scheduleTypeQuery,
        LVAN: categoryQuery?.V === false ? false : prev?.sche?.LVAN,
      },
      cat: { ...categoryQuery },
    }));
  }, [categoryQuery]);

  useEffect(() => {
    if (
      filterStorage &&
      !isStorageFilterExist &&
      Object.keys(allQueries).length > 0
    ) {
      const { setFilter } = filterStorage;
      Object.keys(allQueries).forEach(key => {
        setFilter(prev => {
          return {
            ...prev,
            [key]:
              typeof allQueries?.[key] === 'string'
                ? allQueries?.[key]
                : {
                    ...allQueries[key],
                  },
          };
        });
      });
      if (
        Object.keys(allQueries).includes('sche') &&
        Object.keys(allQueries).includes('cat') &&
        Object.keys(allQueries).includes('div') &&
        Object.keys(allQueries).includes('date')
      )
        setIsStorageFilterExist(prev => true);
    }
  }, [allQueries]);

  return {
    setScheduleTypeQuery,
    setDivisionQuery,
    setCategoryQuery,
    setDateQuery,
    allQueries,
  };
};

export default useControlQueries;
