import { useEffect, useState } from 'react';
import checkIsDefaultYard from '../../../utils/Common/Handler/checkIsDefaultYard';

const useControlReturnYardQueries = ({ yardList, divList, filterStorage }) => {
  const { filter } = filterStorage;

  const defaultDiv = localStorage.getItem('defaultDiv');

  const [scheduleTypeQuery, setScheduleTypeQuery] = useState({
    DROP: typeof filter?.sche?.DROP === 'boolean' ? filter?.sche?.DROP : true,
    LIVE: typeof filter?.sche?.LIVE === 'boolean' ? filter?.sche?.LIVE : true,
  });

  const [divisionQuery, setDivisionQuery] = useState({
    ...Object.fromEntries(
      divList?.map(div => [
        div,
        typeof filter?.div?.[div] === 'boolean'
          ? filter?.div?.[div]
          : div === defaultDiv,
      ]),
    ),
  });

  const [categoryQuery, setCategoryQuery] = useState({
    M: typeof filter?.cat?.M === 'boolean' ? filter?.cat?.M : true,
    X: typeof filter?.cat?.X === 'boolean' ? filter?.cat?.X : true,
  });

  const [yardQuery, setYardQuery] = useState({});
  useEffect(() => {
    if (!_.isEmpty(yardList)) {
      setYardQuery(prev => {
        return {
          ...Object.fromEntries(
            yardList
              ?.map(yard => [
                yard,
                typeof filter?.['y-com']?.[yard] === 'boolean'
                  ? filter?.['y-com']?.[yard]
                  : checkIsDefaultYard({ yard, defaultDiv }),
              ])
              ?.filter(item => typeof item[0] === 'string'),
          ),
        };
      });
    }
  }, [yardList]);

  const [statusQuery, setStatusQuery] = useState({
    W: typeof filter?.color?.W === 'boolean' ? filter?.color?.W : true,
    B: typeof filter?.color?.B === 'boolean' ? filter?.color?.B : true,
    G: typeof filter?.color?.G === 'boolean' ? filter?.color?.G : true,
    Y: typeof filter?.color?.Y === 'boolean' ? filter?.color?.Y : true,
  });

  const [allQueries, setAllQueries] = useState({});
  const [isStorageFilterExist, setIsStorageFilterExist] = useState(
    !!filterStorage?.filter,
  );

  useEffect(() => {
    // If allQueries is have key not {}
    if (
      filterStorage &&
      !isStorageFilterExist &&
      Object.keys(allQueries).length > 0
    ) {
      const { setFilter } = filterStorage;
      Object.keys(allQueries).forEach(key => {
        setFilter(prev => {
          return {
            ...prev,
            [key]: {
              ...allQueries[key],
            },
          };
        });
      });
      if (
        Object.keys(allQueries).includes('sche') &&
        Object.keys(allQueries).includes('cat') &&
        Object.keys(allQueries).includes('div') &&
        Object.keys(allQueries).includes('color') &&
        Object.keys(allQueries).includes('y-com')
      )
        setIsStorageFilterExist(prev => true);
    }
  }, [allQueries]);

  useEffect(() => {
    setAllQueries(prev => ({
      ...prev,
      sche: { ...scheduleTypeQuery },
      div: { ...divisionQuery },
      cat: { ...categoryQuery },
      color: { ...statusQuery },
    }));
    if (!_.isEmpty(yardList)) {
      setAllQueries(prev => ({
        ...prev,
        'y-com': { ...yardQuery },
      }));
    }
  }, [scheduleTypeQuery, divisionQuery, categoryQuery, yardQuery, statusQuery]);

  return {
    setScheduleTypeQuery,
    setDivisionQuery,
    setCategoryQuery,
    setYardQuery,
    setStatusQuery,
    allQueries,
  };
};

export default useControlReturnYardQueries;
