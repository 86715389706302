import COLUMN_ITEM_PAIR from '../../../constants/Common/columnPair';
import ERROR_MESSAGE_PAIR from '../../../constants/Common/errorMessagePair';

/**
 * @description
 * Function that generate specific error message according to error code.
 * @param {string} code
 * Error code from API
 * @returns {string}
 * Error message
 */
export const specificCodeErrorMessageGenerator = ({
  code,
  fields,
  dependency,
}) => {
  /** Check specific code */
  if (code === 'dependent' || code === 'same_location') {
    const getFieldPair = fields?.map(field => COLUMN_ITEM_PAIR?.[field]);
    const getDependencyPair = dependency?.map(
      field => COLUMN_ITEM_PAIR?.[field],
    );

    return `${ERROR_MESSAGE_PAIR?.[code]} ${getFieldPair?.join(', ')}${
      getDependencyPair ? ` and ${getDependencyPair?.join(', ')}` : ''
    }`;
  }
  if (code === 'job_started') {
    return `Cannot ${fields?.[0]} assigned driver. This job is already started.`;
  }
  if (code === 'geofence_center_missing') {
    return `${fields?.[0]}'s geofence center coordinate is missing.`;
  }
  if (code === 'geofence_not_found') {
    return `${fields?.[0]}'s geofence is not found.`;
  }
  if (code === 'button_not_active') {
    return `This ${fields?.[0]} button is not activated. Please contact division manager.`;
  }
  if (code === 'main_permission_missing') {
    return `Main Page permission is required`;
  }
  if (code === 'permission_missing_for_first_page') {
    return `Please change user's default page to update MAIN`;
  }
  if (code === 'specific_location_required') {
    return `Location ${COLUMN_ITEM_PAIR?.[fields?.[0]]} ${
      COLUMN_ITEM_PAIR?.[fields?.[1]]
    } is required.`;
  }
  if (code === 'ap_location_assigned') {
    return `AP has been assigned to the job that includes the ${fields[0]}.`;
  }
  if (code === 'zip_code_timezone_invalid') {
    return `Cannot find timezone with the given zip code.`;
  }
  if (code === 'converted_wo_exists') {
    return `This workorder has been converted to ${
      COLUMN_ITEM_PAIR?.[fields?.[0]]
    }. If you want to change this workorder, please delete or rollback the ${
      fields[1]
    }.`;
  }
  if (code === 'empty_schedule_datetime') {
    return `Following location's schedule datetime must have  at least one non-empty value. 
    ${COLUMN_ITEM_PAIR?.[fields?.[0]]}${
      fields?.[1] ? COLUMN_ITEM_PAIR?.[fields?.[1]] : ''
    }${fields?.[2] ? COLUMN_ITEM_PAIR?.[fields?.[2]] : ''}.`;
  }
  if (code === 'value_exists') {
    return `This BK# is currently in use. Please complete all WOs and try again.`;
  }
  if (code === 'wo_not_found') {
    return `WO could not be found.`;
  }
  if (code === 'complete_wo_delete_error') {
    return `Complete WO could not be deleted.`;
  }
  if (code === 'existing_ap_ar_delete_error') {
    return `WO could not be deleted due to existing AP/AR.`;
  }
  if (code === 'edi_204_update_error') {
    return `Unable to update due to existing DAT status in EDI 214.`;
  }
  if (code === 'group_has_user') {
    return `User is assigned. Please try again.`;
  }
  if (code === 'permission_first_page_mismatch') {
    return `Do not match.`;
  }
  if (code === 'not_supported_file_size') {
    return `File size exceeds the maximum limit(2MB).`;
  }
  if (code === 'qb_auth_code') {
    return `you've been logged out from QuickBooks. Please log back in.`;
  }
  if (code === 'qb_stale_object') {
    return `Something went wrong (${code}). We couldn't sync with QuickBooks. Please try again.`;
  }
  if (code === 'qb_refresh_fail') {
    return `Something went wrong (${code}). We couldn't sync with QuickBooks. Please try again.`;
  }
  if (code === 'qb_not_found') {
    return `Something went wrong (${code}). We couldn't sync with QuickBooks. Please try again.`;
  }
  if (code === 'qb_invalid') {
    return `Something went wrong (${code}). We couldn't sync with QuickBooks. Please try again.`;
  }
  if (code === 'qb_unknown_error') {
    return `Something went wrong (${code}). We couldn't sync with QuickBooks. Please try again.`;
  }
  if (code === 'qb_unauthenticated') {
    return `Something went wrong (${code}). We couldn't sync with QuickBooks. Please try again.`;
  }
  if (code === 'qb_already_exist') {
    return `Something went wrong (${code}). We couldn't sync with QuickBooks. Please try again.`;
  }
  if (code === 'qb_invoice_not_found') {
    return `Something went wrong (${code}). We couldn't sync with QuickBooks. Please try again.`;
  }
  if (code === 'qb_work_order_not_found') {
    return `Something went wrong (${code}). We couldn't sync with QuickBooks. Please try again.`;
  }
  if (code === 'wo_deleted') {
    return `WO # has been deleted.`;
  }
  if (code === 'job_not_completed') {
    return `Please finish the job first.`;
  }
  if (code === 'dual_job_exists') {
    return `Cannot ${
      COLUMN_ITEM_PAIR?.[fields?.[0]]
    } this workorder. Dual job exists.`;
  }
  if (code === 'division_has_wo') {
    return `WO rule cannot be modified due to existing WO.`;
  }
  if (code === 'bill_no_creation_failed') {
    return `Please update ${COLUMN_ITEM_PAIR?.[fields?.[0]]}.`;
  }
  if (code === 'cannot_be_past') {
    return `${COLUMN_ITEM_PAIR?.[fields?.[0]]} and ${
      COLUMN_ITEM_PAIR?.[fields?.[1]]
    } cannot be set to the past`;
  }
  if (code === 'navigation_failed') {
    return `Failed to load navigation. Please check a mobile app.`;
  }
  if (code === 'qb_locked') {
    return `You don't have the auth to update. Please contact to admin.`;
  }
  if (code === 'address_invalid_error') {
    return `Invalid address. Ensure Address_01, City, State, Zip, and Country are entered correctly.`;
  }
  if (code === 'invalid_location') {
    return `Invalid location set. Please check the location and try again.`;
  }
  if (code === 'qb_non_zero_balance') {
    return `The entity you are trying to delete has an open balance.`;
  }
  if (code === 'dual_job_reassign') {
    return `Cannot reassign driver to the dual job.`;
  }
  if (code === 'converted_export_wo_delete') {
    return `Cannot delete converted Export WO. Please use "Import" button instead.`;
  }
  if (code === 'return_location_not_found') {
    return `Please add a location before creating the schedule.`;
  }
  if (code === 'return_schedule_update_not_allowed') {
    return `Unable to change schedule date time for in-progress jobs.`;
  }
  if (code === 'driver_assigned') {
    return `A driver has been assigned to the ${
      COLUMN_ITEM_PAIR?.[fields?.[0]]
    }. Please unassign the driver first.`;
  }

  const getCodeErrorMessage = ERROR_MESSAGE_PAIR?.[code];
  return getCodeErrorMessage;
};

/**
 * @description
 * Function that generate specific error message according to error fields.
 * @param {list} fields
 * List of error fields
 * @return {string}
 * Error message
 */
export const specificFieldsErrorMessageGenerator = ({ fields }) => {
  /** Check specific fields */
  /** Will be updated */
  return '';
};
