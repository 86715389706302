import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AdvancedSearchModal from '../../../components/TmsReport/Statistics/Modal/AdvancedSearchModal';
import CommonLoading from '../../../components/CommonLoading/CommonLoading';
import CommonErrorModal from '../../../components/CommonErrorModal/CommonErrorModal';
import HeaderButtons from '../../../components/TmsReport/Statistics/HeaderButtons';
import PeriodNavigator from '../../../components/TmsReport/Statistics/PeriodNavigator';
import StatTable from '../../../components/TmsReport/Statistics/StatTable';
import StyledStatAreaWrapper from '../../../styles/TmsReport/Statistics/StyledStatAreaWrapper';
import useGetStatisticsList from '../../../hooks/TmsReport/Statistics/useGetStatisticsList';
import generateInitOptions from '../../../utils/TmsReport/Statistics/Generator/generateInitOptions';
import {
  STATISTICS_SORTED_DIVISION,
  STATISTICS_TABLE_TITLE_VALUE,
} from '../../../constants/TmsReport/Statistics/statisticsValues';
import useFilterStorage from '../../../hooks/Common/Filter/useFilterStorage';

const Statistics = () => {
  const originDivisionList = useSelector(state => state?.common?.allDiv);

  const filterStorage = useFilterStorage({
    storageName: 'statisticsFilter',
  });
  const { filter } = filterStorage;

  const [divisionList, setDivisionList] = useState([]);
  useEffect(() => {
    const tempDivisionList = [
      ...STATISTICS_SORTED_DIVISION?.filter(item =>
        originDivisionList?.includes(item),
      ),
      ...originDivisionList?.filter(
        item => !STATISTICS_SORTED_DIVISION?.includes(item),
      ),
    ];

    setDivisionList(tempDivisionList);
  }, [originDivisionList]);

  const [cycle, setCycle] = useState('weekly');

  const [dateQuery, setDateQuery] = useState({});

  const [divQuery, setDivQuery] = useState({});

  const [customDivOptions, setCustomDivOptions] = useState([]);
  const [isOpenCustomDiv, setIsOpenCustomDiv] = useState(false);

  useEffect(() => {
    const tempDivQuery = Object.fromEntries(
      divisionList?.map(div => [
        div,
        typeof filter?.div?.[div] === 'boolean' ? filter?.div?.[div] : true,
      ]),
    );
    setDivQuery(tempDivQuery);

    const { setFilter } = filterStorage;
    if (Object?.keys?.(tempDivQuery)?.length > 0) {
      setFilter(prev => ({
        ...prev,
        div: tempDivQuery,
      }));

      setCustomDivOptions(
        generateInitOptions({
          divisionList,
          setQuery: setDivQuery,
          filterStorage,
        }),
      );
    }
  }, [divisionList]);

  const [isOpenAdvancedSearch, setIsOpenAdvancedSearch] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const { allStatisticsList, currentDate, ...navDateProps } =
    useGetStatisticsList({
      cycle,
      dateQuery,
      setIsLoading,
      setIsError,
      setErrorMessage,
      setIsOpenErrorModal,
    });

  const [statisticsList, setStatisticsList] = useState({});
  useEffect(() => {
    const filterDivisionList = [
      'ALL',
      ...Object?.keys?.(divQuery)?.filter(div => divQuery?.[div]),
    ];

    const sortedTypeData = Object.keys(STATISTICS_TABLE_TITLE_VALUE).reduce(
      (result, key) => {
        if (allStatisticsList[key]) {
          result[key] = allStatisticsList[key];
        }
        return result;
      },
      {},
    );

    Object.keys(sortedTypeData).forEach(key => {
      sortedTypeData[key] = Object.fromEntries(
        filterDivisionList.map(div => [div, sortedTypeData[key][div]]),
      );
    });

    setStatisticsList(sortedTypeData);
  }, [allStatisticsList, divQuery]);

  const excelProps = {
    cycle,
    statisticsList,
    currentDate,
  };

  return (
    <>
      <div className="flex flex-col pr-[40px] h-full">
        <HeaderButtons
          setIsOpenAdvancedSearch={setIsOpenAdvancedSearch}
          customDivOptions={customDivOptions}
          setDivQuery={setDivQuery}
          isOpenCustomDiv={isOpenCustomDiv}
          setIsOpenCustomDiv={setIsOpenCustomDiv}
          filterStorage={filterStorage}
          {...excelProps}
        />
        <PeriodNavigator
          cycle={cycle}
          setDateQuery={setDateQuery}
          {...navDateProps}
        />
        <StyledStatAreaWrapper>
          {Object.keys(STATISTICS_TABLE_TITLE_VALUE)?.map(title => {
            const tableData = statisticsList?.[title] || {};
            return (
              <StatTable
                key={title}
                tableTitle={title}
                tableData={tableData}
                cycle={cycle}
                currentDate={currentDate}
              />
            );
          })}
        </StyledStatAreaWrapper>
      </div>
      <CommonLoading open={isLoading || isError} />
      <AdvancedSearchModal
        open={isOpenAdvancedSearch}
        setIsOpen={setIsOpenAdvancedSearch}
        cycle={cycle}
        setCycle={setCycle}
        currentDate={currentDate}
        setDateQuery={setDateQuery}
      />
      <CommonErrorModal
        open={isOpenErrorModal}
        setIsOpen={setIsOpenErrorModal}
        message={errorMessage}
      />
    </>
  );
};

export default Statistics;
