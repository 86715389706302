import handleCheckboxChange from '../../Common/handleCheckboxChange';

const generateInitOptions = ({ divisionList, setQuery, filterStorage }) => {
  const initOptions = [];
  divisionList?.forEach(division => {
    initOptions.push({
      label: division,
      value: division,
      defaultValue: filterStorage?.filter?.div?.[division],
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery,
          filterStorage,
          item: 'div',
        }),
    });
  });
  return initOptions;
};

export default generateInitOptions;
