import styled from 'styled-components';

const StyledWeeklyItemContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${props => props?.$backgroundColor || 'white'};
  padding: 10px 11px;
  line-height: 14px;
  gap: 5px;
`;

export default StyledWeeklyItemContentWrapper;
