import React from 'react';
import StyledWeeklyItemLeftBorder from '../../../../styles/TmsReport/Schedules/StyledWeeklyItemLeftBorder';
import handleWOClick from '../../../../utils/TmsReport/Common/handleWOClick';
import WEEKLY_THEME from '../../../../constants/TmsReport/Schedules/weeklyTheme';
import StyledWeeklyItemWrapper from '../../../../styles/TmsReport/Schedules/StyledWeeklyItemWrapper';
import StyledWeeklyItemContentWrapper from '../../../../styles/TmsReport/Schedules/StyledWeeklyItemContentWrapper';
import StyledWeeklyItemContentText from '../../../../styles/TmsReport/Schedules/StyledWeeklyItemContentText';

const WeeklyTableItem = ({ wo, nameSet }) => {
  const colorTheme = WEEKLY_THEME?.[wo.color_type];
  const scheduleType = wo?.sche_type === 'LVAN' ? 'VAN' : wo?.sche_type || '';
  const scheduleTime = wo?.[nameSet?.sche_time]
    ? `, ${wo?.[nameSet?.sche_time]?.substring(0, 5)}`
    : '';
  const equipNo =
    wo?.categoty === 'VAN' || wo?.categoty === 'BROKEAGE'
      ? wo.trailer_no
      : wo.cont_no;

  return (
    <StyledWeeklyItemWrapper
      onClick={() => {
        handleWOClick(wo);
      }}
    >
      <StyledWeeklyItemLeftBorder $backgroundColor={colorTheme?.borderColor} />
      <StyledWeeklyItemContentWrapper
        $backgroundColor={colorTheme?.backgroundColor}
      >
        <StyledWeeklyItemContentText $fontWeight={700}>
          {`${wo?.category}${scheduleTime} ${scheduleType}`}
        </StyledWeeklyItemContentText>
        <StyledWeeklyItemContentText>
          <span className="min-h-[14px]">{equipNo}</span>
          <span className="min-h-[14px]">{wo?.ch_no}</span>
          <span className="min-h-[14px]"> {wo?.[nameSet.loc]}</span>
          <span className="min-h-[14px]">
            {`${wo?.[nameSet.city] || ''}
             ${wo?.[nameSet.state] || ''}
             ${wo?.[nameSet.zip] || ''}`}
          </span>
          <span className="min-h-[14px]">
            {`${wo?.[nameSet.driver_id] || ''}
             ${wo?.[nameSet.driver_name] || ''}`}
          </span>
        </StyledWeeklyItemContentText>
      </StyledWeeklyItemContentWrapper>
    </StyledWeeklyItemWrapper>
  );
};

export default WeeklyTableItem;
