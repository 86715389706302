import React, { useEffect, useState } from 'react';
import CommonCustomColumnModal from '../../CommonCustomColumnModal';
import DispatchGrid from '../DispatchGrid';
import usePatchColumnOrder from '../../../hooks/Dispatch/Common/usePatchColumnOrder';
import generateColumnItems from '../../../utils/Dispatch/Common/Generator/generateColumnItems';
import handleUserColsApply from '../../../utils/Dispatch/Common/Handler/handleUserColsApply';
import getColumnCustomStatus from '../../../utils/Dispatch/Common/Generator/getColCustomStatus';
import { LOCATION_TAB_FILTERS } from '../../../constants/Dispatch/Common/filterButtonSettings';
import useGetDispatchList from '../../../hooks/Dispatch/Common/useGetDispatchList';

const DispatchByStatus = ({
  status,
  woStatus,
  category,
  locStatus,
  subFilter,
  allQueries,
  isEditable,
  gridRef,
  customizeCols,
  setCustomizeCols,
  setIsMessageModalOpen,
  setMessageModalMessage,
  setIsLoadingOpen,
}) => {
  /* Get rowData for Grid */
  const { dispatchList, getListLoading } = useGetDispatchList({
    ...allQueries,
    subFilter,
  });

  /* Hook for col custom */
  const { mutate: mutateCustomizeCols } = usePatchColumnOrder({
    setCustomizeCols,
    setMessageModalMessage,
    setIsMessageModalOpen,
  });

  const waitingList = [getListLoading];
  useEffect(() => {
    setIsLoadingOpen(![...waitingList].every(value => value === false));
  }, [...waitingList]);

  return (
    <>
      <DispatchGrid
        gridRef={gridRef}
        subFilter={subFilter}
        status={status}
        locStatus={locStatus}
        category={category}
        rowData={dispatchList}
        isEditable={isEditable}
        setMessageModalMessage={setMessageModalMessage}
        setIsMessageModalOpen={setIsMessageModalOpen}
        setIsLoadingOpen={setIsLoadingOpen}
      />
      <CommonCustomColumnModal
        openModal={customizeCols}
        setOpenModal={setCustomizeCols}
        status={getColumnCustomStatus(woStatus)}
        selectedItems={
          dispatchList?.dsp_column?.map(item => {
            if (item.equip) {
              return {
                ...item,
                equip: item?.equip?.map(equipItem =>
                  equipItem === 'load_empty' ? 'empty_load_status' : equipItem,
                ),
              };
            }
            return item;
          }) || []
        }
        columnItems={generateColumnItems(
          LOCATION_TAB_FILTERS?.includes(subFilter) ? locStatus : status,
        )}
        onApplyClick={({ buttonRef, appliedColumnList }) => {
          handleUserColsApply({
            mutate: mutateCustomizeCols,
            userID: dispatchList?.user_id,
            appliedColumnList,
            status: LOCATION_TAB_FILTERS?.includes(subFilter)
              ? locStatus
              : status,
            buttonRef,
          });
        }}
      />
    </>
  );
};

export default DispatchByStatus;
