import React, { useState } from 'react';
import dayjs from 'dayjs';
import { DOWN_ICON } from '../../../../constants/Order/Common/path';
import generateIssueDescription from '../../../../utils/TmsReport/EarlyWarning/Generator/generateIssueDescription';
import { BOX_COLOR_OBJECT } from '../../../../constants/TmsReport/EarlyWarning/boxColorObject';

const CustomAccordion = ({
  warningData,
  earlyWarningPatchMutator,
  setIsShowToast,
  setConfirmStatus,
  earlyWarningEditPermission,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="outline-none w-full px-[34px] rounded-[5px]">
      <div
        className={`border-[1px] my-2 font-[500] text-[12px]
        ${open ? 'rounded-t rounded-b' : 'rounded'}
        ${BOX_COLOR_OBJECT?.[warningData?.level]?.borderColor}
        ${BOX_COLOR_OBJECT?.[warningData?.level]?.bgColor}
        `}
      >
        <div
          className={`flex justify-between items-center text-white pl-[20px] pr-[19px] py-2 cursor-pointer rounded-[5px]         
          ${BOX_COLOR_OBJECT?.[warningData?.level]?.borderColor}
          ${BOX_COLOR_OBJECT?.[warningData?.level]?.bgColor}`}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <div>
            <span
              className={`text-[12px] ${
                warningData?.level > 4 ? 'font-[800]' : 'font-[400]'
              }`}
            >
              [ LEVEL {warningData?.level} ]
            </span>
            <span className="text-[12px] font-[500] pl-[10px]">
              {warningData?.cont_no}
            </span>
          </div>

          <div className="flex space-x-3 items-center">
            <p className="font-[400] text-[12px]">
              {dayjs(warningData?.created_date)?.format('MM/DD/YY')}{' '}
            </p>
            <img
              src={DOWN_ICON}
              alt="downIcon"
              className={`${!open && 'rotate-180'}`}
            />
          </div>
        </div>
        <div
          className={`flex flex-col justify-between pl-[7px] pr-[7px] pt-[10px] pb-[9px]  bg-white min-h-[140px] border-[1px] rounded-b-[5px] ${
            open ? 'block' : 'hidden'
          } `}
        >
          <div>
            <div className="flex flex-row pl-[13px] ">
              <div className="w-[52px] text-[12px] font-[700]">WO #</div>
              <span className="font-[400] text-[12px]">
                {warningData?.wo_no}
              </span>
            </div>
            <div className="flex flex-row mt-[5px] pl-[13px]">
              <div className="w-[52px] text-[12px] font-[700]">CONT #</div>
              <span className="font-[400] text-[12px]">
                {warningData?.cont_no}
              </span>
            </div>
            <div className="my-[11px]">
              <div className="h-[1px] w-full bg-[#EEEEEE]" />
            </div>

            <p className="font-[400] text-[12px] pl-[13px]">
              {generateIssueDescription({
                days: warningData?.days_hours,
                frequency: warningData?.frequency,
                warningFrom: warningData?.warn_from,
                waringTo: warningData?.warn_to,
                warningIssue: warningData?.warning_issue,
                level: warningData?.level,
              })}
            </p>
          </div>

          <div className="flex justify-end space-x-[7px]">
            <button
              onClick={() => {
                const bcDetailTab = window.open(
                  `/bc-detail/detail-info/${warningData?.category?.toLowerCase()}/${
                    warningData?.wo_no
                  }`,
                  'bc-detail-tab',
                );
              }}
              className={`w-[34px] bg-white  py-1 rounded border-[1px]
              ${BOX_COLOR_OBJECT?.[warningData?.level]?.borderColor}
              ${BOX_COLOR_OBJECT?.[warningData?.level]?.fontColor}
              `}
              type="button"
            >
              Link
            </button>
            {earlyWarningEditPermission && (
              <button
                onClick={() => {
                  setIsShowToast(true);
                  setConfirmStatus('confirm');
                  earlyWarningPatchMutator({ seq: warningData?.seq });
                }}
                className={`w-[56px] text-white px-[6px] py-[7.5px] rounded font-[500] text-[12px]
              ${BOX_COLOR_OBJECT?.[warningData?.level]?.bgColor}
              `}
                type="button"
              >
                Confirm
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomAccordion;
