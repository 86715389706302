import { useEffect, useState } from 'react';
import createReturnYardFilter from '../../../utils/DailyReport/ReturnYard/Generator/createReturnYardFilter';

const useFilterStorage = ({ ...props }) => {
  const { storageName } = props;

  // Local storage filter
  const [filter, setFilter] = useState(() => {
    // Get filter from local stoarge
    const localStorageFilter = localStorage.getItem(storageName);
    // If there is a filter in local storage, return it
    if (localStorageFilter) {
      // Parse to JSON
      return JSON.parse(localStorageFilter);
    }
    // Set default filter to null
    return null;
  });

  // Set filter when the value is changed
  useEffect(() => {
    if (filter) localStorage.setItem(storageName, JSON.stringify(filter));
  }, [filter]);

  return { filter, setFilter };
};

export default useFilterStorage;
