import React from 'react';
import StatRowContents from '../StatRowContents';
import StatRowCostContents from '../StatRowCostContents';
import StyledDivisionTitle from '../../../../../styles/TmsReport/Statistics/StyledDivisionTitle';
import {
  STATISTICS_TABLE_NORMAL_TYPE_LIST,
  STATISTICS_TABLE_COST_TYPE_LIST,
  STATISTICS_ROW_DIVISION_COLOR_VALUE,
} from '../../../../../constants/TmsReport/Statistics/statisticsValues';

const StatDivisionGroup = ({ rowData, tableTitle, ...props }) => {
  const { division } = props;
  const divisionColor = STATISTICS_ROW_DIVISION_COLOR_VALUE[division];

  return (
    <div className="division-group">
      <StyledDivisionTitle
        className="left-div-sticky"
        divisionColor={divisionColor}
        tableTitle={tableTitle}
      >
        {division}
      </StyledDivisionTitle>
      {STATISTICS_TABLE_NORMAL_TYPE_LIST.includes(tableTitle) && (
        <div className="row">
          {Object.entries(rowData)?.map(([key, data]) => (
            <StatRowContents
              key={String(division + key)}
              rowTitle={key}
              rowData={data}
              divisionColor={divisionColor}
              tableTitle={tableTitle}
              {...props}
            />
          ))}
        </div>
      )}
      {STATISTICS_TABLE_COST_TYPE_LIST.includes(tableTitle) && (
        <div className="row">
          {Object.entries(rowData)?.map(([key, data]) => (
            <StatRowCostContents
              key={String(division + key)}
              rowTitle={key}
              rowData={data}
              divisionColor={divisionColor}
              tableTitle={tableTitle}
              {...props}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default StatDivisionGroup;
